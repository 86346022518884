import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextDrive } from "src/Contexts/Drive/DriveContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const useHttpDrive = () => {
  const { setQtdRegisters, setLoadingTable } = useConfigPageContext();
  const { handleGetAlert } = useLayoutMainContext();
  const { setListFiles } = useContextDrive();

  const handleGet = async () => {
    setLoadingTable(true);

    api
      .get(`/files`)
      .then((res) => {
        setListFiles(res.data.data);
        setQtdRegisters(res.data.meta.total);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingTable(false));
  };

  return { handleGet };
};
