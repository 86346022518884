import {
  Box,
  styled,
  Typography,
  LinearProgress,
  linearProgressClasses,
} from "@mui/material";
import { CardCore } from "src/Pages/components/CardCore/CardCore";
import { CardLineCore } from "src/Pages/components/CardFileCore/CardLineCore";
import { UseGetMetricsDrive } from "./Hooks/UseGetMetricsDrive";
import { Children, useEffect } from "react";
import { useContextDrive } from "src/Contexts/Drive/DriveContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 12,
  borderRadius: 6,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[800],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    transition: "background-color 0.3s ease",
  },
}));

export const CardMemory = () => {
  const { valuesMetrics } = useContextDrive();
  const value = valuesMetrics?.meta.totalSize || 0;
  const percentageUsed = valuesMetrics?.meta.percentageUsed || 0;

  const { handleSubmit } = UseGetMetricsDrive();
  const { attTable } = useConfigPageContext();

  useEffect(() => {
    handleSubmit();
  }, [attTable]);

  return (
    <CardCore sxStyle={{ margin: 0 }}>
      <Box sx={{ margin: { xs: "8px 0 32px 0", sm: "8px 0 48px 0" } }}>
        <Typography
          sx={{ fontSize: { xs: "12px", sm: "16px" }, mb: { xs: 1, md: 1.5 } }}
        >
          Memoria utilizada: {value}MB de{" "}
          {(valuesMetrics?.meta.limit || 0) / 1024 || 0}GB
        </Typography>

        <Box>
          <BorderLinearProgress
            variant="determinate"
            value={percentageUsed}
            sx={{
              "& .MuiLinearProgress-bar": {
                backgroundColor:
                  percentageUsed > 90 ? "#dc0e0e" : "primary.main",
              },
            }}
          />
        </Box>
      </Box>

      {Children.toArray(
        valuesMetrics?.data.map((item) => {
          return (
            <Box sx={{ margin: { xs: "18px 6px", md: "24px 8px" } }}>
              <CardLineCore data={item} />
            </Box>
          );
        })
      )}
    </CardCore>
  );
};
