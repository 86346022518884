import { Children, useEffect } from "react";
import { TableCore, TableRowCore, TdCore } from "src/Pages/components";
import { useContextCompany } from "src/Contexts/Company/CompanyContext";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";
import { FormatMoney } from "src/shared/Utils/FormatMoney";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { CheckIcon } from "src/Pages/components/Icons/Icons";
import ClearIcon from "@mui/icons-material/Clear";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import { TheadPayments } from "./TheadPayments";
import { handleChipCoreCategories } from "src/Pages/components/CategoryComponentMap";
import { UseGetPaymentMethod } from "./UseGetPaymentMethod";
import { Box } from "@mui/material";
import { getLinkWhatApp } from "src/Pages/components/CardPlans/CardPlans";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { CancelOutlined, HelpCenter, Money } from "@mui/icons-material";

export const TablePayments = () => {
  const { handleGet } = UseGetPaymentMethod();
  const { userPerfil } = useAuthContext();
  const { valuesPaymentCheckout } = useContextCompany();

  const handleClickOpen = (url: string) => {
    window.open(url, "_blank");
  };

  useEffect(() => {
    handleGet();
  }, []);

  return (
    <TableCore
      isNotPagination
      THead={<TheadPayments />}
      cardAdd={{
        buttons: [
          <>
            <Box
              component="a"
              href={getLinkWhatApp(
                "5511984939024",
                `Olá, me chamo ${userPerfil?.name}, e tenho duvidas: `
              )}
              sx={{ textDecoration: "none", marginRight: "8px" }}
              target="_blank"
            >
              <ButtonCore
                size="small"
                startIcon={<HelpCenter />}
                title="Tirar duvidas"
                variant="outlined"
              />
            </Box>
            <Box
              component="a"
              href={getLinkWhatApp(
                "5511984939024",
                `Olá, me chamo ${userPerfil?.name}, quero cancelar meu plan: .`
              )}
              sx={{ textDecoration: "none" }}
              target="_blank"
            >
              <ButtonCore
                size="small"
                startIcon={<CancelOutlined />}
                title="Cancelar plano/Gerenciar"
              />
              ,
            </Box>
          </>,
        ],
      }}
      title="Histórico de pagamento"
      heightTable="calc(500px - 6rem)"
      qtdList={valuesPaymentCheckout?.length}
      sxStyle={{ margin: 0, padding: 0 }}
    >
      {Children.toArray(
        valuesPaymentCheckout?.map((item) => {
          return (
            <TableRowCore>
              <TdCore values={`${FormatDateBR(item.dueDate)}`} />
              <TdCore
                values={`R$ ${FormatMoney(item.value.toFixed(2))}`}
                sxStyle={{
                  color: (theme) => theme.custom.color.money,
                }}
              />

              <TdCore
                values={handleChipCoreCategories(
                  item.billingType || "Não definido"
                )}
              />

              <TdCore
                values={`${
                  item?.paymentDate ? FormatDateBR(item.paymentDate) : ""
                }`}
              />

              <TdCore values={handleChipCoreCategories(item.status)} />

              <TdCore
                values={
                  <ButtonCore
                    variant="outlined"
                    startIcon={
                      item.status === "CONFIRMED" ? (
                        <CheckIcon color="success" />
                      ) : (
                        <Money color="warning" />
                      )
                    }
                    color={item.status === "CONFIRMED" ? "success" : "warning"}
                    title={item.status === "CONFIRMED" ? "Pago" : "Pagar"}
                    onClick={() => handleClickOpen(item.invoiceUrl)}
                  />
                }
              />
              <TdCore
                values={
                  <ButtonCore
                    startIcon={
                      item.status === "CONFIRMED" ? (
                        <RequestPageIcon />
                      ) : (
                        <ClearIcon color="error" />
                      )
                    }
                    variant="outlined"
                    title="Recibo"
                    disabled={item.status !== "CONFIRMED"}
                    color={item.status === "CONFIRMED" ? "success" : "error"}
                    onClick={() =>
                      handleClickOpen(item?.transactionReceiptUrl || "")
                    }
                  />
                }
              />
            </TableRowCore>
          );
        })
      )}
    </TableCore>
  );
};
