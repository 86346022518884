import React, { useEffect } from "react";
import { ModalCore } from "src/Pages/components";

import { Divider, Grid, Typography } from "@mui/material";
import { CardCore } from "src/Pages/components/CardCore/CardCore";
import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { useResetInputs } from "src/Contexts";

interface IModalCancelContract {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalGridCommissions = ({
  openModal,
  setOpenModal,
}: IModalCancelContract) => {
  const { valuesInputsCommissions } = useContextComissoes();
  const { resetInputs } = useResetInputs();

  const parc1 = valuesInputsCommissions.parc_1;
  const parc2 = valuesInputsCommissions.parc_2;
  const parc3 = valuesInputsCommissions.parc_3;
  const parc4 = valuesInputsCommissions.parc_4;
  const parc5 = valuesInputsCommissions.parc_5;
  const parc6 = valuesInputsCommissions.parc_6;

  const installments = [parc1, parc2, parc3, parc4, parc5, parc6];

  useEffect(() => {
    if (!openModal) resetInputs();
  }, [openModal]);

  return (
    <ModalCore
      open={openModal}
      setOpen={setOpenModal}
      sxStyle={{ width: "600px" }}
      title="Grade de comissão"
    >
      <Grid container>
        {installments.map((item, index) => {
          return (
            <Grid item xs={6} sm={4} key={index}>
              <CardCore sxStyle={{ alignItems: "center", textAlign: "center" }}>
                <Typography>{index + 1} - Parcela</Typography>
                <Divider sx={{ margin: "8px 0" }} />
                <Typography> {item}%</Typography>
              </CardCore>
            </Grid>
          );
        })}
      </Grid>
    </ModalCore>
  );
};
