import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { styled, Theme, CSSObject, Typography } from "@mui/material";

import { useLayoutMainContext } from "../../Contexts/MainLayoutContext";

import { AsideTitle } from "./aside/AsideTitle";
import { ToggleOpenMenu } from "../Header/components/ToggleOpenMenu/ToggleOpenMenu";
import { LogoHeader } from "../Header/components/LogoHeader/LogoHeader";

export const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: ".3s",
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: ".3s",
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(6.5)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const DrawerDesktop = () => {
  const { openAside } = useLayoutMainContext();

  return (
    <Box>
      <CssBaseline />

      <Drawer
        variant="permanent"
        open={openAside}
        sx={{
          "& .MuiPaper-root": {
            zIndex: "11",
            borderRadius: "0",
          },
        }}
      >
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "8px ",
          }}
        >
          <ToggleOpenMenu />
          <LogoHeader />
        </Typography>

        <List sx={{ padding: "12px" }}>
          <AsideTitle />
        </List>

        {/* <Box
          sx={{
            position: "absolute", // Fixar no fundo do Drawer
            bottom: "16px", // Distância do fundo
            left: "50%", // Centralizar horizontalmente
            transform: "translateX(-50%)", // Ajustar posição para centralização
            overflow: "hidden",
          }}
        >
          <AvatarLogout />
        </Box> */}
      </Drawer>
    </Box>
  );
};
