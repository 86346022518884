import { Grid } from "@mui/material";
import { FilesDrive } from "./Componentes/FilesDrive";
// import { FilterDrive } from "./Componentes/FilterDrive";

export const IndexDrive = () => {
  return (
    <Grid item container spacing={2}>
      {/* <Grid item xs={12}>
        <FilterDrive />
      </Grid> */}

      <Grid item container xs={12}>
        <FilesDrive />
      </Grid>
    </Grid>
  );
};
