import {
  Box,
  Menu,
  alpha,
  Divider,
  Tooltip,
  MenuItem,
  IconButton,
  Typography,
} from "@mui/material";
import { CardCore } from "../CardCore/CardCore";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

import video from "./IconsSvg/Drive/video-red.svg";
import image from "./IconsSvg/Drive/jpg-img.svg";
import excel from "./IconsSvg/Drive/excel.svg";
import pdf from "./IconsSvg/Drive/pdf.svg";
import txt from "./IconsSvg/Drive/txt.svg";
import audio from "./IconsSvg/Drive/audio.svg";
import doc from "./IconsSvg/Drive/doc.svg";
import { MoreVertIcon } from "../Icons/Icons";
import { handleIfLimitCharacter } from "src/shared/Utils/LimitText";
import { handleFormatSize } from "src/shared/Utils/getFileSize";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useState } from "react";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { HandleDownloadFiles } from "src/shared/Utils/HandleDownloadFiles";
import { UseDelete } from "../ModalDeleteCore/Hooks/UseDelete";
import { ModalDeleteCore } from "../ModalDeleteCore/ModalDeleteCore";

export const listIconsType: Record<string, string> = {
  document: doc,
  docx: doc,
  video,
  image,
  audio,
  excel,
  txt,
  pdf,
};

const getIconByMimeType = (mimeType: string): string => {
  if (mimeType.startsWith("image/")) return listIconsType.image;
  if (mimeType.startsWith("video/")) return listIconsType.video;
  if (mimeType.startsWith("audio/")) return listIconsType.audio;
  if (mimeType.includes("spreadsheet")) return listIconsType.excel;
  if (mimeType.includes("text")) return listIconsType.txt;
  if (mimeType.includes("pdf")) return listIconsType.pdf;

  const extensionMapping: Record<string, string> = {
    "application/pdf": listIconsType.document,
    "application/msword": listIconsType.document,
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      listIconsType.docx,
  };

  return extensionMapping[mimeType] || listIconsType.document;
};

interface ICardFileCore {
  data: {
    originalName: string;
    mimeType?: string;
    url: string;
    size: number;
    createdAt: string;
    id: string;
    key: string;
  };
}

export const CardFileCore = ({
  data: { originalName, mimeType, url, size, createdAt, id },
}: ICardFileCore) => {
  const { handleGetAlert } = useLayoutMainContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    handleDelete,
    open: openModalDelete,
    loading: loadingDelete,
    setId: setIdModalDelete,
    setOpen: setOpenModalDelete,
  } = UseDelete({ url: "/files" });

  return (
    <>
      <CardCore
        elevation={1}
        sxStyle={{
          position: "relative",
          margin: 0,
          width: "100%",
          height: "140px",
        }}
      >
        <Tooltip title="Clique para abrir">
          <Box
            component={"img"}
            onClick={() => window.open(url, "_blank")}
            src={
              mimeType?.includes("image")
                ? url
                : mimeType
                ? getIconByMimeType(mimeType)
                : listIconsType.document
            }
            alt="Ícone"
            width={35}
            height={35}
            sx={{
              borderRadius: "2px",
              transition: "transform 0.2s ease-in-out",
              "&:hover": { transform: "scale(1.1)" },
              marginBottom: "16px",
              cursor: "pointer",
            }}
          />
        </Tooltip>

        <Typography
          sx={{
            fontWeight: 500,
            marginBottom: "16px",
            fontSize: { xs: "12px", sm: "14px" },
          }}
        >
          {handleIfLimitCharacter({ LIMIT: 14, value: originalName })}
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > *": {
              fontSize: "12px",
              color: (theme) => theme.palette.text.secondary,
            },
          }}
        >
          <Typography>{handleFormatSize(size)}</Typography>
          <Box
            sx={{
              margin: "0 6px",
              width: "2px",
              height: "2px",
              flexShrink: 0,
              borderRadius: "50%",
              background: "black",
            }}
          />
          <Typography>{FormatDateBR(createdAt)}</Typography>
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: "8px",
            right: "8px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box>
            {/* <Checkbox
              {...label}
              icon={<StarIcon sx={{ color: "#FFAB00" }} fontSize="medium" />}
              checkedIcon={<StarBorderIcon color="disabled" />}
            /> */}
          </Box>
          <Box>
            <IconButton
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <MoreVertIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      </CardCore>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        slotProps={{ paper: { sx: { width: "180px" } } }}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
      >
        <MenuItem onClick={() => window.open(url, "_blank")}>
          <VisibilityOutlinedIcon fontSize="small" sx={{ mr: "12px" }} />
          Visualizar
        </MenuItem>

        <MenuItem
          onClick={() =>
            navigator.clipboard
              .writeText(url)
              .then(() => handleGetAlert({ message: "LINK copiado" }))
          }
        >
          <ContentCopyOutlinedIcon fontSize="small" sx={{ mr: "12px" }} />
          Copiar Link
        </MenuItem>

        <MenuItem onClick={() => HandleDownloadFiles(url, originalName).then()}>
          <FileDownloadOutlinedIcon fontSize="small" sx={{ mr: "12px" }} />
          Download
        </MenuItem>

        <Divider
          sx={{
            border: (theme) =>
              `1px dashed ${alpha(theme.palette.text.secondary, 0.3)}`,
          }}
        />

        <MenuItem
          onClick={() => {
            handleClose();
            setIdModalDelete(id);
            setOpenModalDelete(true);
          }}
        >
          <DeleteForeverIcon
            fontSize="small"
            sx={{ mr: "12px", color: "#ef5350" }}
            color="error"
          />
          <Typography sx={{ color: "#ef5350", fontWeight: 500 }}>
            Deletar
          </Typography>
        </MenuItem>
      </Menu>

      <ModalDeleteCore
        loading={loadingDelete}
        modalOpen={openModalDelete}
        no="Não, Cancelar"
        titlePrimary="Você deseja excluir arquivo ?"
        yes="Sim, Deletar !"
        onClickFalse={() => setOpenModalDelete(false)}
        onClickTrue={handleDelete}
      />
    </>
  );
};
