import { useState } from "react";
import { useContextDrive } from "src/Contexts/Drive/DriveContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const UseGetMetricsDrive = () => {
  const { handleGetAlert } = useLayoutMainContext();
  const { setValuesMetrics } = useContextDrive();

  const [loading, setLoading] = useState(false);

  const handleUpload = async () => {
    setLoading(true);

    return api
      .get(`/files/personal-metrics`)
      .then((res) => {
        setValuesMetrics(res.data);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return { handleSubmit: handleUpload, loading };
};
