import { Grid } from "@mui/material";
import { CardMemory } from "./CardMemory/CardMemory";
import { UploadDrive } from "./Upload/UploadDrive";
import { IndexDrive } from "./Files/IndexDrive";

export const PageDrive = () => {
  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <UploadDrive />
      </Grid>

      <Grid item container spacing={3}>
        {/* O CardMemory fica primeiro em telas menores e à direita em telas maiores */}
        <Grid
          item
          xs={12}
          md={3.5}
          spacing={2}
          sx={{ order: { xs: -1, md: 1 } }} // Define a ordem responsiva
        >
          <Grid item xs={12}>
            <CardMemory />
          </Grid>
        </Grid>

        {/* O IndexDrive fica depois do CardMemory em telas pequenas e normal em telas grandes */}
        <Grid item xs={12} md={8.5} sx={{ order: { xs: 1, md: 0 } }}>
          <IndexDrive />
        </Grid>
      </Grid>
    </Grid>
  );
};
