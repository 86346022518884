import React, { useState, useCallback, ReactNode } from "react";
import { useDropzone } from "react-dropzone";
import { Box, CircularProgress } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { handleGenerateUuid } from "src/shared/Utils/handleGenerateUuid";

export interface IListPDF {
  data: number;
  status: string;
  name: string;
  previewUrl: string;
  pdf: ArrayBuffer;
  size: number;
  type: string;
  id: string;
}

interface UploadFilesProps {
  accept?: { [key: string]: string[] };
  onFilesChange?: (files: IListPDF[]) => void;
  children?: ReactNode;
  isLoading?: boolean;
}

export const DropZoneCore: React.FC<UploadFilesProps> = ({
  accept = { "image/jpeg": [], "image/png": [] },
  onFilesChange,
  children,
  isLoading,
}) => {
  const [listFiles, setListFiles] = useState<IListPDF[]>([]);

  const handleUploadFile = useCallback(
    async (files: File[]) => {
      const processedFiles: IListPDF[] = await Promise.all(
        files.map(async (file) => {
          const arrayBuffer = await file.arrayBuffer();
          const fileUrl = URL.createObjectURL(file);

          return {
            id: handleGenerateUuid(),
            name: file.name,
            data: file.lastModified,
            size: file.size,
            status: "Na fila",
            pdf: arrayBuffer,
            type: file.type,
            previewUrl: fileUrl,
          };
        })
      );
      const updatedList = [...processedFiles];

      setListFiles(updatedList);
      if (onFilesChange) onFilesChange(updatedList);
    },
    [listFiles, onFilesChange]
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      handleUploadFile(acceptedFiles);
    },
    [handleUploadFile]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop,
    disabled: isLoading,
  });

  return (
    <Box
      component="section"
      {...getRootProps()}
      sx={{
        height: "200px",
        padding: "8px",

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",

        background: (theme) => alpha(theme.palette.primary.main, 0.05),

        cursor: isLoading ? "default" : "pointer",
        borderRadius: "12px",
        border: (theme) => `1px dashed ${theme.palette.primary.main}`,

        transition: "background 0.3s ease",
        "&:hover": {
          background: (theme) =>
            isLoading
              ? alpha(theme.palette.primary.main, 0.05)
              : alpha(theme.palette.primary.main, 0.1),
        },
      }}
    >
      <input {...getInputProps()} />
      {isLoading ? <CircularProgress /> : children}
    </Box>
  );
};
