import { createContext, useContext, ReactNode, useState, FC } from "react";

import {
  IListFiles,
  IContextDrive,
  IValuesMetricsDrive,
  IValuesFilterInstallments,
} from "./DriveTypes";
import { valuesDefaultFilterInstallments } from "./ValueDefaultInputDrive/ValueDefaultInputDrive";
import { IListPDF } from "src/Pages/components/DropZoneCore/DropZoneCore";

const DriveContext = createContext({} as IContextDrive);

export const DriveContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [valuesFilterInstallments, setValuesFilterInstallments] =
    useState<IValuesFilterInstallments>(valuesDefaultFilterInstallments);

  const [valuesMetrics, setValuesMetrics] = useState<IValuesMetricsDrive>();

  const [listFiles, setListFiles] = useState<IListFiles[]>([]);
  const [uploadDrive, setUploadDrive] = useState<IListPDF[]>([]);

  return (
    <DriveContext.Provider
      value={{
        setValuesFilterInstallments,
        valuesFilterInstallments,
        setValuesMetrics,
        setUploadDrive,
        valuesMetrics,
        setListFiles,
        uploadDrive,
        listFiles,
      }}
    >
      {children}
    </DriveContext.Provider>
  );
};

export const useContextDrive = (): IContextDrive => useContext(DriveContext);
