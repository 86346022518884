import { Box, Typography } from "@mui/material";

import { handleFormatSize } from "src/shared/Utils/getFileSize";
import { MetricsDrive } from "src/Contexts/Drive/DriveTypes";
import video from "./IconsSvg/Drive/video-red.svg";
import image from "./IconsSvg/Drive/jpg-img.svg";
import audio from "./IconsSvg/Drive/audio.svg";
import doc from "./IconsSvg/Drive/doc.svg";

interface ICardLineCore {
  data: MetricsDrive;
}

export const CardLineCore = ({
  data: { totalSize, total, category },
}: ICardLineCore) => {
  const objIconAndName = {
    IMAGE: { icon: image, name: "Imagem" },
    DOCUMENT: { icon: doc, name: "Documento" },
    AUDIO: { icon: audio, name: "Áudio" },
    VIDEO: { icon: video, name: "Vídeo" },
  };

  const { icon, name } = objIconAndName[category] || {
    icon: doc,
    name: "Documento",
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ marginRight: "16px" }}>
          <Box
            component={"img"}
            src={icon}
            alt="Ícone"
            sx={{
              width: { xs: "25px", md: "33px" },
              height: { xs: "25px", md: "33px" },
            }}
          />
        </Box>

        <Box>
          <Typography
            sx={{ fontSize: { xs: ".6rem", sm: "0.85rem" }, fontWeight: 600 }}
          >
            {name}
          </Typography>

          <Typography
            sx={{
              color: (theme) => theme.palette.text.secondary,
              fontSize: { xs: ".6rem", sm: "0.75rem" },
            }}
          >
            {total} itens
          </Typography>
        </Box>
      </Box>

      <Typography
        sx={{ fontSize: { xs: ".6rem", sm: "0.85rem" }, fontWeight: 600 }}
      >
        {handleFormatSize(totalSize)}
      </Typography>
    </Box>
  );
};
