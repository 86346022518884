import { useState } from "react";
import { useResetInputs } from "src/Contexts";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextDrive } from "src/Contexts/Drive/DriveContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { IListPDF } from "src/Pages/components/DropZoneCore/DropZoneCore";
import { api } from "src/shared/setup/API/api";

export const UseFormUploadDrive = () => {
  const { handleGetAlert } = useLayoutMainContext();
  const { setAttTable } = useConfigPageContext();
  const { setUploadDrive } = useContextDrive();
  const { resetInputs } = useResetInputs();

  const [loading, setLoading] = useState(false);

  const handleUpload = async (data: IListPDF[]) => {
    setLoading(true);

    const formData = new FormData();

    data.forEach((file) => {
      const blob = new Blob([file.pdf], { type: file.type });
      formData.append("files", blob, file.name);
    });

    return api
      .post(`/files`, formData)
      .then((res) => {
        setAttTable((item) => !item);
        handleGetAlert({ message: res.data.message });
        resetInputs();
        setUploadDrive([]);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return { handleSubmit: handleUpload, loading };
};
