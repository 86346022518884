import { Grid } from "@mui/material";
import { CardFileCore } from "src/Pages/components/CardFileCore/CardFileCore";
import { useHttpDrive } from "./Hooks/useHttpDrive";
import { Children, useEffect } from "react";
import { useContextDrive } from "src/Contexts/Drive/DriveContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";

export const FilesDrive = () => {
  const { handleGet } = useHttpDrive();
  const { listFiles } = useContextDrive();
  const { attTable } = useConfigPageContext();
  const { openAside } = useLayoutMainContext();

  useEffect(() => {
    handleGet();
  }, [attTable]);

  return (
    <Grid container item xs={12} spacing={2}>
      {Children.toArray(
        listFiles.map((item) => {
          return (
            <Grid xs={6} sm={6} md={4} lg={3} xl={openAside ? 3 : 2} item>
              <CardFileCore data={item} />
            </Grid>
          );
        })
      )}
    </Grid>
  );
};
