import { alpha, Box, Typography } from "@mui/material";
import { useCallback } from "react";
import {
  DropZoneCore,
  IListPDF,
} from "src/Pages/components/DropZoneCore/DropZoneCore";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { UseFormUploadDrive } from "./Hook/UseFormUploadDrive";

export const UploadDrive = () => {
  const { handleSubmit, loading: loadingSubmit } = UseFormUploadDrive();

  const handleUploadFile = useCallback(async (files: IListPDF[]) => {
    await handleSubmit(files);
  }, []);

  return (
    <>
      <DropZoneCore
        onFilesChange={handleUploadFile}
        isLoading={loadingSubmit}
        accept={{
          "image/jpeg": [],
          "image/png": [],
          "image/JPG": [],
          "image/WEBP": [],
          "image/GIF": [],

          "application/pdf": [],
          "application/msword": [],
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            [],
          "application/vnd.ms-excel": [],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            [],
          "text/plain": [],
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "80px",
            height: "80px",
            borderRadius: "50%",
            marginBottom: "16px",
            background: (theme) => alpha(theme.palette.primary.main, 0.3),
          }}
        >
          <DriveFolderUploadIcon fontSize="large" />
        </Box>
        <Box>
          <Typography>ou arraste seu arquivo</Typography>
          <Typography sx={{ color: (theme) => theme.palette.primary.main }}>
            Clique aqui
          </Typography>
        </Box>
      </DropZoneCore>
    </>
  );
};
